import moment from 'moment';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'EcogyApp',
  data() {
    return {
      language: localStorage.getItem("locale") || "de"
    };
  },
  methods: {
    langchange() {
      localStorage.setItem("locale", this.language);
      this.$i18n.locale = this.language;
      moment.locale(this.language);
    }
  }
});