import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "login"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 0,
  class: "invalid-feedback left"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback left"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_card, null, {
    header: _withCtx(() => [_createElementVNode("h2", null, _toDisplayString(_ctx.$t('login.resetlinktitle')), 1), _createElementVNode("div", {
      class: "desc",
      innerHTML: _ctx.$t('login.resetdescription')
    }, null, 8, _hoisted_2)]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      class: "login-form",
      "label-position": "top",
      ref: "form",
      onSubmit: _withModifiers(_ctx.chgpassword, ["prevent"])
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        prop: "pass",
        label: _ctx.$t('login.password')
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.password1,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.password1 = $event),
          placeholder: _ctx.$t('login.password'),
          "prefix-icon": "lock",
          "show-password": ""
        }, null, 8, ["modelValue", "placeholder"]), _ctx.v$.password1.$invalid ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_ctx.password1 && _ctx.v$.password1.minLength.$invalid ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('login.password.minlength')), 1)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        prop: "pass",
        label: _ctx.$t('login.password')
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.password2,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.password2 = $event),
          placeholder: _ctx.$t('login.password2'),
          "prefix-icon": "lock",
          "show-password": ""
        }, null, 8, ["modelValue", "placeholder"]), !_ctx.v$.password1.$invalid && _ctx.v$.password2.sameAsPassword.$invalid ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('login.password.sameAsPassword')), 1)])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          loading: _ctx.loading,
          disabled: _ctx.v$.$invalid,
          class: "login-button",
          type: "primary",
          "native-type": "submit",
          block: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login.savepassword')), 1)]),
          _: 1
        }, 8, ["loading", "disabled"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["onSubmit"])]),
    _: 1
  })]);
}