let apiurl = "https://api.ecogy.app";

if(window.location.hostname == 'admin-dev.ecogy.app')
    apiurl = "https://api-dev.ecogy.app";

if(window.location.hostname == 'localhost')
    apiurl = "https://api-dev.ecogy.app";

export default {
    TenantUrl: apiurl + "/Tenant/",
    TenantListUrl: apiurl + "/Tenant/list",
    LoginUrl: apiurl + "/Security/LoginAdmin",
    ChangeTenant: apiurl + "/Security/changeTenant/",
    PwdResetUrl: apiurl + "/Security/RestPasswordByAdmin/",
    SetPasswordUrl: apiurl + "/Security/SetPassword/",
    ChargingDetailUrl: apiurl + "/Charging/${id}",
    ChargingHistoryUrl: apiurl + "/Charging/chargingHistory",
    ChargingPointsUrl: apiurl + "/Charging/points",
    ChargingPointsUnknownUrl: apiurl + "/Charging/points/unknown",
    ChargingStartUrl: apiurl + "/Charging/${point}/start",
    ChargingActualUrl: apiurl + "/Charging/${point}/actualChargingProcess",
    ChargingPointResetUrl: apiurl + "/Charging/${point}/reset",
    ChargingStopUrl: apiurl + "/Charging/${point}/${id}/stop",
    AdminUsersUrl: apiurl + "/Security/GetAdminUsers",
    AdminUsersPwdUrl: apiurl + "/Security/SetPasswordByAdmin",
    CardListUrl: apiurl + "/Charging/RfidCards",
    UpdateHistoryUrl: apiurl + "/Charging/UpdateStatuses",
    CardListUnknowsUrl: apiurl + "/Charging/RfidCards/Unknown",
    PriceListUrl: apiurl + "/Charging/Prices",
    SaveAdminUserUrl: apiurl + "/Security/SaveAdmin",
    ImageUploadUrl: apiurl + "/Tenant/upload"
}