import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import axios from 'axios';
import config from '../config';
import router from '@/router';
import moment from 'moment';
export default defineComponent({
  name: 'ForgotPasswordForm',
  /*components: {
    DTPicker,
  },
  mounted(){
  },*/
  data() {
    return {
      v$: useValidate(),
      email: "",
      loading: false
    };
  },
  validations() {
    return {
      email: {
        required
      }
    };
  },
  methods: {
    getPwd() {
      return btoa(moment().format("YY-MM-DD"));
    },
    async login() {
      try {
        this.v$.$validate(); // checks all inputs
        if (this.v$.$error) {
          this.v$.$errors.forEach(element => {
            ElMessage({
              message: element.$message,
              type: 'error'
            });
          });
        }
        this.loading = true;
        await axios.post(config.PwdResetUrl + localStorage.getItem("locale") + '/' + this.getPwd(), {
          UserName: this.email
        });
        this.loading = false;
        ElMessage({
          message: 'Password sent successfully.',
          type: 'success'
        });
        router.push({
          name: 'home'
        });
      } catch (e) {
        this.loading = false;
        ElMessage({
          message: 'Password could not be sent: ' + e,
          type: 'error'
        });
      }
    }
  }
});