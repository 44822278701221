import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { minLength, required, sameAs } from '@vuelidate/validators';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import axios from 'axios';
import config from '../config';
import router from '@/router';
import moment from 'moment';
export default defineComponent({
  name: 'ForgotPasswordForm',
  /*components: {
    DTPicker,
  },
  mounted(){
  },*/
  data() {
    return {
      v$: useValidate(),
      password1: "",
      password2: "",
      loading: false
    };
  },
  validations() {
    return {
      password1: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        sameAsPassword: sameAs(this.password1)
      }
    };
  },
  methods: {
    getPwd() {
      return btoa(moment().format("YY-MM-DD"));
    },
    async chgpassword() {
      try {
        this.v$.$validate(); // checks all inputs
        if (this.v$.$error) {
          this.v$.$errors.forEach(element => {
            ElMessage({
              message: element.$message,
              type: 'error'
            });
          });
        }
        this.loading = true;
        await axios.post(config.SetPasswordUrl + this.$route.params?.token, {
          Password: this.password1
        });
        this.loading = false;
        ElMessage({
          message: 'Password changed successfully.',
          type: 'success'
        });
        router.push({
          name: 'home'
        });
      } catch (e) {
        this.loading = false;
        ElMessage({
          message: 'Password could not be changed: ' + e,
          type: 'error'
        });
      }
    }
  }
});