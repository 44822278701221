import config from '@/config';
import axios from 'axios';
import { defineComponent } from 'vue';
import { saveLogin } from '@/service/LogingService';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

export default defineComponent({
  name: 'HomeForm',
  data() {
    return {
      selectedTenant: "",
      tenants: []
    };
  },
  async mounted() {
    this.selectedTenant = sessionStorage.getItem("tenant");
    const roleAdmin = sessionStorage.getItem("roleAdmin");
    if (roleAdmin == "*") {
      const g = await axios.get(config.TenantListUrl, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      this.tenants = g.data;
    } else if (Array.isArray(roleAdmin)) {
      const g = await axios.get(config.TenantListUrl, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      this.tenant = g.data.filter(f => roleAdmin.includes(f.id));
    }
  },
  methods: {
    isAdmin: function () {
      return sessionStorage.getItem("role") == "Administrator";
    },
    async changeTenant() {
      var t = this.selectedTenant;
      const g = await axios.get(config.ChangeTenant + t, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      saveLogin(g.data);
      this.selectedTenant = sessionStorage.getItem("tenant");
      ElMessage({
        message: this.$t("home.tenantchanged"),
        type: 'success'
      });
    }
  }
});