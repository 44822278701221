export default {
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Ecogy.app admin portal"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login with our email and password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
    "savepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forgot my password"])},
    "resentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send me my password"])},
    "backlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
    "resettitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
    "resetlinktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register new Password"])},
    "resetdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your new password"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app Hotel Portal"])},
    "rfidcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Cards"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Stations"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Management"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
    "tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Management"])},
    "tenantchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The aktiv Tenant has been changed"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])}
  },
  "transactions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "forceUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force Update from Backend"])},
    "updateDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data has been updated"])},
    "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in update of data"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "cb_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargingstation"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Card"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time"])},
      "energyWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumed kWh"])},
      "stopReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Reason"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Auth."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "statuses": {
        "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initializing"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ssupended"])},
        "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not to invoice"])},
        "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preinvoice"])},
        "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Invoicing"])},
        "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoiced"])},
        "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])}
      }
    },
    "filter": {
      "transactiondate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Card"])},
      "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargingstation"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Results"])}
    }
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app User Management"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Tenant"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
      "resetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send password reset"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage User"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Tenant"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    }
  },
  "tenants": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant management"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charging Backend"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])}
    },
    "edit": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitename"])},
      "logoUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Url"])},
      "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Title"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background Color"])},
      "loginDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Description"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charging Backend"])},
      "chargingBackendId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Backend Id"])},
      "chargingBackendUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Username"])},
      "chargingBackendPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Password"])},
      "chargingBackendClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Client Id"])},
      "chargingBackendClientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Secret"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])},
      "pmsBackendHotelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Code"])},
      "pmsChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Id"])},
      "pmsPreAuthChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre. Auth. Charge Id"])},
      "pmsPreAuthQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre Auth Quantity"])},
      "pmsPreAuthQuantityHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quantity in kWh that will be preauthorized on the PMS Backend. If 0, no preauthorization will be done."])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Tenant"])},
      "PmsBackendUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Backend Url"])},
      "PmsUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Username"])},
      "PmsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Password"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "pmsRfidCardAuthorisationEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Authorisation from PMS Backend?"])},
      "pmsRfidCardAuthorisationTransformationFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Transformation Function"])},
      "pmsSendTotalPriceToPms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Price to PMS"])},
      "pmsSendTotalPriceToPmsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send only quantity, but no price (Price is managed in PMS)"])},
      "pmsSendTotalPriceToPmsTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send quantity and price (Ecogy price is used)"])}
    }
  },
  "rfidCards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Cards Management"])},
    "table": {
      "idTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Tag"])},
      "inTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Transaction?"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Card"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Card"])}
    }
  },
  "loadingStation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Stations"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Station"])}
    },
    "confirmTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Station will be created in this Tenant - The Tennant cannot be changed afterwards."])},
    "confirmReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you want to execute a HARD reset on the Loading Station?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you want to stop the charging process"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starttime"])}
  },
  "prices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Management"])},
    "table": {
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid From"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for kWh"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Price"])}
    }
  }
}