import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "../components/Login.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import ResetPassword from "../components/ResetPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/login",
    name: 'login',
    component: Login,
  },
  {
    path: "/forgot",
    name: 'forgot',
    component: ForgotPassword,
  },
  {
    path: "/reset/:token",
    name: 'reset',
    component: ResetPassword,
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "start" */ '../views/Transactions.vue')
  },
  {
    path: '/rfidcards',
    name: 'rfidcards',
    component: () => import(/* webpackChunkName: "start" */ '../views/RfidCards.vue')
  },
  {
    path: '/loadingstations',
    name: 'loadingstations',
    component: () => import(/* webpackChunkName: "start" */ '../views/LoadingStation.vue')
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import(/* webpackChunkName: "start" */ '../views/Price.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "start" */ '../views/Users.vue')
  },
  {
    path: '/user/:userid',
    name: 'useredit',
    component: () => import(/* webpackChunkName: "start" */ '../views/UserEdit.vue')
  },
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import(/* webpackChunkName: "start" */ '../views/Tenants.vue')
  },
  {
    path: '/tenant/:tenantid',
    name: 'tenantedit',
    component: () => import(/* webpackChunkName: "start" */ '../views/TenantEdit.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/forgot', '/reset'];
  const superAdminPages = ['/users'];
  const authRequired = !publicPages.find(f=>to.path.startsWith(f));
  const superAdminauthRequired = superAdminPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  }
  else if(superAdminauthRequired && !loggedIn){
    next('/login');
  } else {
    next();
  }
});

export default router
