import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import { makeLogin } from '../service/LogingService';
import router from '@/router';
export default defineComponent({
  name: 'LoginForm',
  /*components: {
    DTPicker,
  },
  mounted(){
  },*/
  data() {
    return {
      v$: useValidate(),
      email: "",
      password: null,
      loading: false,
      loginTitle: this.logTitle || this.$t('login.title')
    };
  },
  validations() {
    return {
      email: {
        required
      },
      password: {
        required
      }
    };
  },
  methods: {
    async login() {
      try {
        this.v$.$validate(); // checks all inputs
        if (this.v$.$error) {
          this.v$.$errors.forEach(element => {
            ElMessage({
              message: element.$message,
              type: 'error'
            });
          });
        }
        this.loading = true;
        localStorage.setItem("lastusername", this.email);
        await makeLogin(this.email, this.password);
        this.loading = false;
        ElMessage({
          message: 'Login successfull.',
          type: 'success'
        });
        router.push({
          name: 'home'
        });
      } catch (e) {
        this.loading = false;
        ElMessage({
          message: 'Login invalid: ' + e,
          type: 'error'
        });
      }
    }
  }
});