import { createApp } from "vue";
import './element.scss';
import App from "./App.vue";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import i18n from './i18n'

let host = location.pathname.split('/')[1];
if(!host)
  host = "ecogy";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
.use(i18n)
.use(router)
.mount("#app"); 
